<template>
  <div class="text-center">
    <button
      v-if="!pdfType"
      class="
        rs-report__cta
        v-btn v-btn--is-elevated v-btn--has-bg
        theme--dark
        v-size--default
        primary
      "
      target="_blank"
      @click="handleCta"
    >
    <template v-if="isEditing">
    
      <!-- <EditorButton v-if="isEditing" :value="value" @input="emitUpdate" /> -->

    </template>

      <template v-else>
        <ReportContentOutput :value="value" />
      </template>
    </button>
  </div>
</template>

<script>
// import EditorButton from "@/components/editors/EditorButton";
import ReportContentOutput from "@/components/ReportContentOutput.vue";

export default {
  components: {
    // EditorButton,
    ReportContentOutput,
  },
  props: {
    isEditing: {
      type: Boolean,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
    advisor: {
      type: Object,
    },
    prospectId: {
      type: String,
    },
    pdfType: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    emitUpdate(e) {
      this.$emit("update:value", e);
    },

    handleCta() {
      if (this.isEditing) {
        return;
      } else if (this.advisor?.collectContactPostSurvey) {
        this.$router.push("/reduce-risk/" + this.prospectId);
      } else if (this.advisor?.customReportCtaUrl) {
        window.open(this.advisor.customReportCtaUrl, "_self", "rel=noreferrer");
      } else {
        this.$router.push("/contact-requested/" + this.prospectId);
      }
    },
  },
};
</script>
